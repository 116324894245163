// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const dashboard = (menus) => {
    // console.log(menus);
    const check = menus.filter((x) => x.name === 'Dashboard');
    if (check.length > 0) {
        return {
            id: 'dashboard',
            // title: check[0].name,
            type: 'group',
            children: [
                {
                    id: 'Dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard',
                    // icon: icons.IconDashboard,
                    breadcrumbs: false
                }
            ]
        };
    } else {
        return {};
    }
};

export default dashboard;
