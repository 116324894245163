// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const setting = (menus) => {
    const check = menus.filter(
        (x) => x.name === 'Dynamic Context' || x.name === 'City' || x.name === 'Credentials' || x.name === 'Maintance Mode'
    );
    if (check.length > 0) {
        const settings = {
            id: 'setting',
            title: 'Setting',
            type: 'group',
            children: []
        };

        const checkDynamic = menus.filter((x) => x.name === 'Dynamic Context');
        if (checkDynamic.length > 0) {
            settings.children.push({
                id: 'dynamiccontext',
                title: checkDynamic[0].name,
                type: 'collapse',
                icon: icons.IconWindmill,
                children: [
                    {
                        id: 'dynamicpages',
                        title: 'Pages',
                        type: 'item',
                        url: '/dynamicpages',
                        breadcrumbs: false
                    },
                    {
                        id: 'dynamicslider',
                        title: 'Sliders',
                        type: 'item',
                        url: '/dynamicslider',
                        breadcrumbs: false
                    },
                    {
                        id: 'dynamicbanner',
                        title: 'Banners',
                        type: 'item',
                        url: '/dynamicbanner',
                        breadcrumbs: false
                    },
                    {
                        id: 'dynamicads',
                        title: 'Ads',
                        type: 'item',
                        url: '/dynamicads',
                        breadcrumbs: false
                    }
                ]
            });
        }

        const checkCity = menus.filter((x) => x.name === 'City');
        if (checkCity.length > 0) {
            settings.children.push({
                id: 'city',
                title: checkCity[0].name,
                type: 'item',
                url: '/city',
                breadcrumbs: false
            });
        }

        const checkCredentials = menus.filter((x) => x.name === 'Credentials');
        if (checkCredentials.length > 0) {
            settings.children.push({
                id: 'credential',
                title: checkCredentials[0].name,
                type: 'item',
                url: '/credentials',
                breadcrumbs: false
            });
        }

        const checkMaintance = menus.filter((x) => x.name === 'Maintance Mode');
        if (checkMaintance.length > 0) {
            settings.children.push({
                id: 'maintancemode',
                title: checkMaintance[0].name,
                type: 'item',
                url: '/maintancemode',
                breadcrumbs: false
            });
        }
        sessionStorage.setItem('page', 1);
        sessionStorage.setItem('search', '');
        sessionStorage.setItem('order', '');
        return settings;
    } else {
        return {};
    }
};

export default setting;
