// assets
import { IconDashboard } from '@tabler/icons';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
// constant
const icons = { IconDashboard };
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -10,
        border: `2px solid var(--primary)`,
        padding: '0 4px',
        background: '#673ab7',
        color: '#fff'
    }
}));
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const office = (menus) => {
    const check = menus.filter(
        (x) => x.name === 'Company' || x.name === 'Composition' || x.name === 'Contact-us' || x.name === 'Categories'
    );
    if (check.length > 0) {
        const offce = {
            id: 'office',
            title: 'Office Management',
            type: 'group',
            children: []
        };

        const checkCompany = menus.filter((x) => x.name === 'Company');
        if (checkCompany.length > 0) {
            offce.children.push({
                id: 'company',
                title: checkCompany[0].name,
                type: 'item',
                url: '/company',
                icon: icons.IconShadow,
                breadcrumbs: false
            });
        }

        const checkComposition = menus.filter((x) => x.name === 'Composition');
        if (checkComposition.length > 0) {
            offce.children.push({
                id: 'composition',
                title: checkComposition[0].name,
                type: 'item',
                url: '/composition',
                icon: icons.IconShadow,
                breadcrumbs: false
            });
        }

        const checkContact = menus.filter((x) => x.name === 'Contact-us');
        if (checkContact.length > 0) {
            offce.children.push({
                id: 'contact',
                title: checkContact[0].name,
                type: 'collapse',
                icon: icons.IconWindmill,
                children: [
                    {
                        id: 'contactus',
                        title: (
                            <StyledBadge
                                // badgeContent={cartItems && cartItems.length ? cartItems.length : '0'}
                                badgeContent={'0'}
                            >
                                {'Contacts'}
                            </StyledBadge>
                        ),
                        type: 'item',
                        url: '/contactus',
                        icon: icons.IconShadow,
                        breadcrumbs: false
                    },
                    {
                        id: 'subject',
                        title: 'Subject',
                        type: 'item',
                        url: '/subject',
                        icon: icons.IconShadow,
                        breadcrumbs: false
                    }
                ]
            });
        }

        const checkCategories = menus.filter((x) => x.name === 'Categories');
        if (checkCategories.length > 0) {
            offce.children.push({
                id: 'General',
                title: checkCategories[0].name,
                type: 'collapse',
                icon: icons.IconWindmill,
                children: [
                    {
                        id: 'category',
                        title: 'Category',
                        type: 'item',
                        url: '/category',
                        breadcrumbs: false
                    },
                    {
                        id: 'subcategory',
                        title: 'Sub Category',
                        type: 'item',
                        url: '/subcategory',
                        breadcrumbs: false
                    }
                ]
            });
        }
        sessionStorage.setItem('page', 1);
        sessionStorage.setItem('search', '');
        sessionStorage.setItem('order', '');
        return offce;
    } else {
        return {};
    }
};

export default office;
