// action - state management
import * as actionTypes from './actions';

export const initialState = {
    data: [],
    loading: true,
    totalPage: 0,
    currentPage: 0,
    totalRows: 0,
    nextPageUrl: ''
};

// ==============|| Users REDUCER ||=============== //

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_COMPANYS:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...action.payload.data]
            };
        case actionTypes.FETCH_UNMAPPED_COMPANYS:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...action.payload.data]
            };
        case actionTypes.FETCH_COMPANYS_NEXT:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...state.data, ...action.payload.data]
            };
        case actionTypes.FETCH_UNMAPPED_COMPANYS_NEXT:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...state.data, ...action.payload.data]
            };
        case actionTypes.FETCH_SEARCH_COMPANYS:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...action.payload.data]
            };
        case actionTypes.ADD_COMPANY:
            return {
                ...state,
                data: [...state.data, ...action.payload]
            };
        case actionTypes.UPDATE_COMPANY:
            return {
                ...state,
                data: state.data.map((x) => {
                    if (x.id === action.id) {
                        x = action.payload;
                    }
                    return x;
                })
            };
        case actionTypes.ACTIVATE_COMPANY:
            return {
                ...state,
                data: state.data.map((x) => {
                    if (x.id === action.payload.id) {
                        x.is_active = x.is_active === 0 ? 1 : 0;
                    }
                    return x;
                })
            };
        // case actionTypes.FETCH_UNMAPPED_POS_PRODUCTS:
        //     return {
        //         currentPage: action.payload.current_page,
        //         totalPage: action.payload.last_page,
        //         totalRows: action.payload.total,
        //         nextPageUrl: action.payload.next_page_url,
        //         data: [...action.payload.data]
        //     };
        // case actionTypes.FETCH_UNMAPPED_POS_PRODUCTS_NEXT:
        //     return {
        //         currentPage: action.payload.current_page,
        //         totalPage: action.payload.last_page,
        //         totalRows: action.payload.total,
        //         nextPageUrl: action.payload.next_page_url,
        //         data: [...state.data, ...action.payload.data]
        //     };
        default:
            return state;
    }
};

export default companyReducer;
