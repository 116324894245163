// action - state management
import * as actionTypes from './actions';

export const initialState = {
    data: [],
    loading: true,
    totalPage: 0,
    currentPage: 0,
    totalRows: 0,
    nextPageUrl: ''
};

// ==============|| Users REDUCER ||=============== //

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ORDERS:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...action.payload.data]
            };
        case actionTypes.FETCH_ORDERS_NEXT:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...state.data, ...action.payload.data]
            };
        case actionTypes.FETCH_SEARCH_ORDERS:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...action.payload.data]
            };
        case actionTypes.ADD_ORDER:
            return {
                ...state,
                data: [...state.data, ...action.payload]
            };
        case actionTypes.UPDATE_ORDER:
            return {
                ...state,
                data: state.data.map((x) => {
                    if (x.id === action.id) {
                        x = action.payload;
                    }
                    return x;
                })
            };
        case actionTypes.ACTIVATE_ORDER:
            return {
                ...state,
                data: state.data.map((x) => {
                    let statuses = '';
                    if (x.id === action.payload.id) {
                        x.status_id = action.payload.comment.status;
                        if (action.payload.status === 2) {
                            statuses = 'Approved';
                        } else if (action.payload.status === 3) {
                            statuses = 'Completed';
                        } else {
                            statuses = 'Return';
                        }
                        x.status = statuses;
                        x.comment = action.payload.comment;
                    }
                    return x;
                })
            };
        default:
            return state;
    }
};

export default orderReducer;
