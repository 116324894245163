// action - state management
import * as actionTypes from './actions';

export const initialState = {
    data: [],
    loading: true,
    totalPage: 0,
    currentPage: 0,
    totalRows: 0,
    nextPageUrl: ''
};

// ==============|| Users REDUCER ||=============== //

const prescriptionReducer = (state = initialState, action) => {
    // console.log(action);
    switch (action.type) {
        case actionTypes.FETCH_PRESCRIPTIONS:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...action.payload.data]
            };
        case actionTypes.FETCH_PRESCRIPTIONS_NEXT:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...state.data, ...action.payload.data]
            };
        case actionTypes.FETCH_SEARCH_PRESCRIPTIONS:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...action.payload.data]
            };
        case actionTypes.ACTIVATE_PRESCRIPTION:
            return {
                ...state,
                data: state.data.map((x) => {
                    if (x.id === action.payload.id) {
                        x.status_id = action.payload.status;
                        x.status = action.payload.status === 2 ? 'Approved' : 'Rejected';
                        x.comment = action.payload.comment;
                    }
                    return x;
                })
            };
        default:
            return state;
    }
};

export default prescriptionReducer;
