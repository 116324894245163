import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import { fontSize } from '@mui/system';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={'/dashboard'}>
        <Logo />
        {/* <h5>Health Pack Pharmacy</h5> */}
    </ButtonBase>
);

export default LogoSection;
