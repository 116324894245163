import { useEffect, useState } from 'react';
// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { GET } from 'constant/RequestAuthService';
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const [counts, setCounts] = useState({});

    const getCounts = async () => {
        const response = await GET('admin/sidebar_counts');
        if (response.status === 200) {
            setCounts(response.data);
        }
        await setTimeout(async () => {
            const response1 = await GET('admin/sidebar_counts');
            if (response1.status === 200) {
                setCounts(response1.data);
            }
        }, 200000);
    };

    useEffect(() => {
        getCounts();
    }, []);

    const Items = menuItem(counts);
    const navItems = Items.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
