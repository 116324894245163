import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/authentication/authentication/Login')));
const AuthForget = Loadable(lazy(() => import('views/authentication/authentication/Forget')));
const AuthReset = Loadable(lazy(() => import('views/authentication/authentication/Reset')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: 'forgetpassword',
            element: <AuthForget />
        },
        {
            path: 'resetpassword',
            element: <AuthReset />
        },
        {
            path: '',
            element: <AuthLogin />
        }
    ]
};

export default AuthenticationRoutes;
