import dashboard from './dashboard';
import order from './order';
import product from './product';
import Mapping from './mapping';
import user from './user';
import office from './office';
import report from './report';
import setting from './setting';
import importing from './import';
import StoredVariables, { GetSession, GetLocalSession } from 'constant/Session';
import { Json } from 'constant/Util';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = (counts) => {
    const menus = Json(GetSession(StoredVariables.menus) || GetLocalSession(StoredVariables.menus));
    const items = [
        dashboard(menus),
        order(menus, counts),
        Mapping(menus),
        product(menus, counts),
        user(menus),
        office(menus),
        report(menus),
        importing(menus),
        setting(menus)
    ];
    return {
        items: items.filter((x) => x.id)
    };
};

export default menuItems;
