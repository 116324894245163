// action - state management
import * as actionTypes from './actions';

export const initialState = {
    data: [],
    loading: true,
    totalPage: 0,
    currentPage: 0,
    totalRows: 0,
    nextPageUrl: ''
};

// ==============|| Users REDUCER ||=============== //

const newposproductCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_UNMAPPED_POS_PRODUCTS_COUNT:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: action.payload.data
            };
        case actionTypes.FETCH_UNMAPPED_POS_PRODUCTS_COUNT_NEXT:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...state.data, ...action.payload.data]
            };
        default:
            return state;
    }
};

export default newposproductCountReducer;
