// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// users
export const FETCH_USERS = '@users/FETCH_USERS';
export const FETCH_USERS_NEXT = '@users/FETCH_USERS_NEXT';
export const FETCH_SEARCH_USERS = '@users/FETCH_SEARCH_USERS';
export const ADD_USER = '@users/ADD_USER';
export const UPDATE_USER = '@users/UPDATE_USER';
export const ACTIVATE_USER = '@users/ACTIVATE_USER';

// pramotions
export const FETCH_PRAMOTIONS = '@users/FETCH_PRAMOTIONS';
export const FETCH_PRAMOTIONS_NEXT = '@users/FETCH_PRAMOTIONS_NEXT';
export const FETCH_SEARCH_PRAMOTIONS = '@users/FETCH_SEARCH_PRAMOTIONS';
// export const ADD_USER = '@users/ADD_USER';
// export const UPDATE_USER = '@users/UPDATE_USER';
// export const ACTIVATE_USER = '@users/ACTIVATE_USER';

// driver
export const FETCH_DRIVERS = '@drivers/FETCH_DRIVERS';
export const FETCH_DRIVERS_NEXT = '@drivers/FETCH_DRIVERS_NEXT';
export const FETCH_SEARCH_DRIVERS = '@drivers/FETCH_SEARCH_DRIVERS';
export const ADD_DRIVER = '@driver/ADD_DRIVER';
export const UPDATE_DRIVER = '@driver/UPDATE_DRIVER';
export const ACTIVATE_DRIVER = '@driver/ACTIVATE_DRIVER';

//company
export const FETCH_COMPANYS = '@company/FETCH_COMPANYS';
export const FETCH_COMPANYS_NEXT = '@company/FETCH_COMPANYS_NEXT';
export const FETCH_SEARCH_COMPANYS = '@company/FETCH_SEARCH_COMPANYS';
export const ADD_COMPANY = '@company/ADD_COMPANY';
export const UPDATE_COMPANY = '@company/UPDATE_COMPANY';
export const ACTIVATE_COMPANY = '@company/ACTIVATE_COMPANY';
export const FETCH_UNMAPPED_COMPANYS = '@company/FETCH_UNMAPPED_COMPANYS';
export const FETCH_UNMAPPED_COMPANYS_NEXT = '@company/FETCH_UNMAPPED_COMPANYS_NEXT';

export const FETCH_UNMAPPED_POS_PRODUCTS_COUNT = '@newposcount/FETCH_UNMAPPED_POS_PRODUCTS_COUNT';
export const FETCH_UNMAPPED_POS_PRODUCTS_COUNT_NEXT = '@newposcount/FETCH_UNMAPPED_POS_PRODUCTS_COUNT_NEXT';

export const FETCH_COMPANY_POS_PRODUCTS = '@newpos/FETCH_COMPANY_POS_PRODUCTS';
export const FETCH_COMPANY_POS_PRODUCTS_NEXT = '@newpos/FETCH_COMPANY_POS_PRODUCTS_NEXT';
export const UPDATE_COMPANY_POS_PRODUCTS = '@newpos/UPDATE_COMPANY_POS_PRODUCTS';
//PRODUCT
export const FETCH_PRODUCTS = '@product/FETCH_PRODUCTS';
export const FETCH_PRODUCTS_NEXT = '@product/FETCH_PRODUCTS_NEXT';
export const FETCH_SEARCH_PRODUCTS = '@product/FETCH_SEARCH_PRODUCTS';
export const ADD_PRODUCT = '@product/ADD_PRODUCT';
export const UPDATE_PRODUCT = '@product/UPDATE_PRODUCT';
export const ACTIVATE_PRODUCT = '@product/ACTIVATE_PRODUCT';
export const FEATURED_PRODUCT = '@product/FEATURED_PRODUCT';

//POS PRODUCT

export const FETCH_POS_PRODUCTS = '@posproduct/FETCH_POS_PRODUCTS';
export const FETCH_POS_PRODUCTS_NEXT = '@posproduct/FETCH_POS_PRODUCTS_NEXT';
export const FETCH_SEARCH_POS_PRODUCTS = '@posproduct/FETCH_SEARCH_POS_PRODUCTS';
// export const ADD_PRODUCT = '@product/ADD_PRODUCT';
// export const UPDATE_PRODUCT = '@product/UPDATE_PRODUCT';
export const ACTIVATE_POS_PRODUCT = '@posproduct/ACTIVATE_POS_PRODUCT';

//MAPPED PRODUCT
export const FETCH_MAPPED_PRODUCTS = '@mappedproduct/FETCH_MAPPED_PRODUCTS';
export const FETCH_MAPPED_PRODUCTS_NEXT = '@mappedproduct/FETCH_MAPPED_PRODUCTS_NEXT';
export const FETCH_SEARCH_MAPPED_PRODUCTS = '@mappedproduct/FETCH_SEARCH_MAPPED_PRODUCTS';
// export const ADD_PRODUCT = '@product/ADD_PRODUCT';
// export const UPDATE_PRODUCT = '@product/UPDATE_PRODUCT';
export const ACTIVATE_MAPPED_PRODUCT = '@mappedproduct/ACTIVATE_MAPPED_PRODUCT';

//order
export const FETCH_ORDERS = '@order/FETCH_ORDERS';
export const FETCH_ORDERS_NEXT = '@order/FETCH_ORDERS_NEXT';
export const FETCH_SEARCH_ORDERS = '@order/FETCH_SEARCH_ORDERS';
export const ADD_ORDER = '@order/ADD_ORDER';
export const UPDATE_ORDER = '@order/UPDATE_ORDER';
export const ACTIVATE_ORDER = '@order/ACTIVATE_ORDER';

//prescriptions
export const FETCH_PRESCRIPTIONS = '@prescription/FETCH_PRESCRIPTIONS';
export const FETCH_PRESCRIPTIONS_NEXT = '@prescription/FETCH_PRESCRIPTIONS_NEXT';
export const FETCH_SEARCH_PRESCRIPTIONS = '@prescription/FETCH_SEARCH_PRESCRIPTIONS';
export const ADD_PRESCRIPTION = '@prescription/ADD_PRESCRIPTION';
export const UPDATE_PRESCRIPTION = '@prescription/UPDATE_PRESCRIPTION';
export const ACTIVATE_PRESCRIPTION = '@prescription/ACTIVATE_PRESCRIPTION';

export const FETCHPRESCRIPTIONS = '@prescription/FETCHPRESCRIPTIONS';
export const FETCHPRESCRIPTIONS_NEXT = '@prescription/FETCHPRESCRIPTIONS_NEXT';

//composition
export const FETCH_COMPOSITIONS = '@composition/FETCH_COMPOSITIONS';
export const FETCH_COMPOSITIONS_NEXT = '@composition/FETCH_COMPOSITIONS_NEXT';
export const FETCH_SEARCH_COMPOSITIONS = '@composition/FETCH_SEARCH_COMPOSITIONS';
export const ADD_COMPOSITION = '@composition/ADD_COMPOSITION';
export const UPDATE_COMPOSITION = '@composition/UPDATE_COMPOSITION';
export const ACTIVATE_COMPOSITION = '@composition/ACTIVATE_COMPOSITION';
