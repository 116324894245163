import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import userReducer from './userReducer';
import driverReducer from './driverReducer';
import companyReducer from './companyReducer';
import productReducer from './productReducer';
import posproductReducer from './posproductReducer';
import newposproductCountReducer from './newposproductCountReducer';
import newposproductReducer from './newposproductReducer';
import mappedproductReducer from './mappedproductReducer';
import orderReducer from './orderReducer';
import prescriptionReducer from './prescriptionReducer';
import compositionReducer from './compositionReducer';
import pramotionReducer from './pramotionReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    users: userReducer,
    drivers: driverReducer,
    companys: companyReducer,
    newposcount: newposproductCountReducer,
    newposs: newposproductReducer,
    products: productReducer,
    posproducts: posproductReducer,
    mappedproducts: mappedproductReducer,
    orders: orderReducer,
    prescriptions: prescriptionReducer,
    compositions: compositionReducer,
    pramotions: pramotionReducer
});

export default reducer;
