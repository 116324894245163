// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const user = (menus) => {
    const check = menus.filter((x) => x.name === 'Employees' || x.name === 'Users' || x.name === 'Driver');
    if (check.length > 0) {
        const users = {
            id: 'user',
            title: 'User Management',
            type: 'group',
            children: []
        };

        const checkEmployees = menus.filter((x) => x.name === 'Employees');
        if (checkEmployees.length > 0) {
            users.children.push({
                id: 'Employees',
                title: checkEmployees[0].name,
                type: 'collapse',
                icon: icons.IconWindmill,
                children: [
                    {
                        id: 'employee',
                        title: 'Employees',
                        type: 'item',
                        url: '/employee',
                        breadcrumbs: false
                    },
                    {
                        id: 'group',
                        title: 'Groups',
                        type: 'item',
                        url: '/groups',
                        breadcrumbs: false
                    }
                ]
            });
        }
        const checkUser = menus.filter((x) => x.name === 'Users');
        if (checkUser.length > 0) {
            users.children.push({
                id: 'Employees',
                title: checkUser[0].name,
                type: 'collapse',
                icon: icons.IconWindmill,
                children: [
                    {
                        id: 'users',
                        title: 'Users',
                        type: 'item',
                        url: '/user',
                        breadcrumbs: false
                    },
                    {
                        id: 'promotional',
                        title: 'Promotional',
                        type: 'item',
                        url: '/promotional',
                        breadcrumbs: false
                    }
                ]
            });
        }

        const checkDriver = menus.filter((x) => x.name === 'Delivery Person');
        if (checkDriver.length > 0) {
            users.children.push({
                id: 'driver',
                title: checkDriver[0].name,
                type: 'item',
                url: '/driver',
                breadcrumbs: false
            });
        }
        sessionStorage.setItem('page', 1);
        sessionStorage.setItem('search', '');
        sessionStorage.setItem('order', '');
        return users;
    } else {
        return {};
    }
};

export default user;
