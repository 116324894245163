// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const report = (menus) => {
    const check = menus.filter((x) => x.name === 'Reports');
    if (check.length > 0) {
        return {
            id: 'report',
            title: check[0].name,
            type: 'group',
            children: [
                {
                    id: 'report',
                    title: 'Reports',
                    type: 'item',
                    url: '/report',
                    icon: icons.IconShadow,
                    breadcrumbs: false
                }
            ]
        };
        sessionStorage.setItem('page', 1);
        sessionStorage.setItem('search', '');
        sessionStorage.setItem('order', '');
    } else {
        return {};
    }
};

export default report;
