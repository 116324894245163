// action - state management
import * as actionTypes from './actions';

export const initialState = {
    data: [],
    loading: true,
    totalPage: 0,
    currentPage: 0,
    totalRows: 0,
    nextPageUrl: ''
};

// ==============|| Users REDUCER ||=============== //

const compositionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_COMPOSITIONS:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...action.payload.data]
            };
        case actionTypes.FETCH_COMPOSITIONS_NEXT:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...state.data, ...action.payload.data]
            };
        case actionTypes.FETCH_SEARCH_COMPOSITIONS:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...action.payload.data]
            };
        case actionTypes.ADD_COMPOSITION:
            return {
                ...state,
                data: [...state.data, ...action.payload]
            };
        case actionTypes.UPDATE_COMPOSITION:
            return {
                ...state,
                data: state.data.map((x) => {
                    if (x.id === action.id) {
                        x = action.payload;
                    }
                    return x;
                })
            };
        case actionTypes.ACTIVATE_COMPOSITION:
            return {
                ...state,
                data: state.data.map((x) => {
                    if (x.id === action.payload.id) {
                        x.is_active = x.is_active === 0 ? 1 : 0;
                        // x.comment = action.payload.comment;
                    }
                    return x;
                })
            };
        default:
            return state;
    }
};

export default compositionReducer;
