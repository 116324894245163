// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const importing = (menus) => {
    const check = menus.filter((x) => x.name === 'Import-Company' || x.name === 'Import-Composition' || x.name === 'Import-Products');
    if (check.length > 0) {
        const imports = {
            id: 'import',
            title: 'Import',
            type: 'group',
            children: []
        };

        const checkCompany = menus.filter((x) => x.name === 'Import-Company');
        if (checkCompany.length > 0) {
            imports.children.push({
                id: 'company_import',
                title: checkCompany[0].name,
                type: 'item',
                url: '/company/import',
                icon: icons.IconShadow,
                breadcrumbs: false
            });
        }
        const checkComposition = menus.filter((x) => x.name === 'Import-Composition');
        if (checkComposition.length > 0) {
            imports.children.push({
                id: 'composition_import',
                title: checkComposition[0].name,
                type: 'item',
                url: '/composition/import',
                breadcrumbs: false
            });
        }

        const checkProduct = menus.filter((x) => x.name === 'Import-Products');
        if (checkProduct.length > 0) {
            imports.children.push({
                id: 'product_import',
                title: checkProduct[0].name,
                type: 'item',
                url: '/product/import',
                breadcrumbs: false
            });
        }
        sessionStorage.setItem('page', 1);
        sessionStorage.setItem('search', '');
        sessionStorage.setItem('order', '');
        return imports;
    } else {
        return {};
    }
};

export default importing;
