import { Buffer } from 'buffer';
import moment from 'moment';
import { toast } from 'react-toastify';
// Base 64 Encode
export const ENCODE = (code) =>
    Buffer.from(`MedAppEn-Coding-$2y.S/${Buffer.from(`${code}`).toString('base64')}/$2y.Dev-Mobitplus-MedAppEn-Coding`).toString('base64');

// Base 64 Decode
export const DECODE = (code) =>
    Buffer.from(`${Buffer.from(`${code}`, 'base64').toString('ascii')}`.split('/')[1], 'base64').toString('ascii');

// reload functionallty
export const Reload = () => window.location.reload();

// json to String
export const Stringify = (json) => JSON.stringify(json);

// string to json
export const Json = (string) => JSON.parse(string);

// function for getting error from validatorjs
export const Validation = async (errordata) => {
    // console.log(errordata);
    return Object.values(errordata).length > 0
        ? Object.values(errordata).map((x) => {
              //   console.log(x);
              return toast.error(`${x}`);
          })
        : [];
};

// Two date difference
export const DateDifference = (start_date = '', end_date = '') => {
    if (start_date !== '' && end_date !== '') {
        const a = moment(start_date);
        const b = moment(end_date);

        const years = a.diff(b, 'year');
        b.add(years, 'years');

        const months = a.diff(b, 'months');
        b.add(months, 'months');

        const days = a.diff(b, 'days');
        // console.log(years + ' years ' + months + ' months ' + days + ' days');
        return `
                ${years > 0 ? `${year} year ` : ''}
                ${months > 0 ? `${months} months ` : ''}
                ${days > 0 ? `${days} days ` : ''}
                ${years > 0 || months > 0 || days > 0 ? `before` : ''}
                ${years === 0 && months === 0 && days === 0 ? `Today` : ''}`;
    }
};
