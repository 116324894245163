// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const Mapping = (menus) => {
    const check = menus.filter((x) => x.name === 'Mapped Products' || x.name === 'Mapped POS');
    if (check.length > 0) {
        const products = {
            id: 'product',
            title: 'Mapping Management',
            type: 'group',
            children: []
        };

        const checkMapped = menus.filter((x) => x.name === 'Mapped Products');
        if (checkMapped.length > 0) {
            products.children.push({
                id: 'mappedproduct',
                title: checkMapped[0].name,
                type: 'item',
                url: '/mappedproduct',
                breadcrumbs: false
            });
        }

        const checkMappedpos = menus.filter((x) => x.name === 'Mapped POS');
        if (checkMappedpos.length > 0) {
            products.children.push({
                id: 'newmappedproduct',
                title: checkMappedpos[0].name,
                type: 'item',
                url: '/posmappedproduct',
                breadcrumbs: false
            });
        }
        sessionStorage.setItem('page', 1);
        sessionStorage.setItem('search', '');
        sessionStorage.setItem('order', '');
        return products;
    } else {
        return {};
    }
};

export default Mapping;
