// action - state management
import * as actionTypes from './actions';

export const initialState = {
    data: [],
    loading: true,
    totalPage: 0,
    currentPage: 0,
    totalRows: 0,
    nextPageUrl: ''
};

// ==============|| Users REDUCER ||=============== //

const newposproductReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_COMPANY_POS_PRODUCTS:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...action.payload.data]
            };
        case actionTypes.FETCH_COMPANY_POS_PRODUCTS_NEXT:
            return {
                currentPage: action.payload.current_page,
                totalPage: action.payload.last_page,
                totalRows: action.payload.total,
                nextPageUrl: action.payload.next_page_url,
                data: [...action.payload.data]
            };
        case actionTypes.UPDATE_COMPANY_POS_PRODUCTS:
            const newData = state.data.map((x) => {
                if (x.RndId === action.payload.x.RndId) {
                    x.mappedRndId = action.payload.x.RndId;
                    x.product = action.payload?.value;
                }
                return x;
            });
            return { ...state, data: newData };
        default:
            return state;
    }
};

export default newposproductReducer;
