// assets
import { IconDashboard } from '@tabler/icons';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
// constant
const icons = { IconDashboard };
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        border: `2px solid var(--primary)`,
        padding: '0 4px',
        background: '#673ab7',
        color: '#fff'
    }
}));
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const product = (menus, counts) => {
    const check = menus.filter(
        (x) => x.name === 'Products' || x.name === 'POS Products' || x.name === 'Request Product' || x.name === 'Notify Product'
    );
    if (check.length > 0) {
        const products = {
            id: 'product',
            title: 'Product Management',
            type: 'group',
            children: []
        };

        const checkProducts = menus.filter((x) => x.name === 'Products');
        if (checkProducts.length > 0) {
            products.children.push({
                id: 'product',
                title: checkProducts[0].name,
                type: 'item',
                url: '/product',
                icon: icons.IconShadow,
                breadcrumbs: false
            });
        }
        const checkPOS = menus.filter((x) => x.name === 'POS Products');
        if (checkPOS.length > 0) {
            products.children.push({
                id: 'posproduct',
                title: checkPOS[0].name,
                type: 'item',
                url: '/posproduct',
                breadcrumbs: false
            });
        }

        const checkRequestProduct = menus.filter((x) => x.name === 'Request Product');
        if (checkRequestProduct.length > 0) {
            products.children.push({
                id: 'productrequest',
                title: (
                    <StyledBadge
                        // badgeContent={cartItems && cartItems.length ? cartItems.length : '0'}
                        badgeContent={counts.requests || '0'}
                        style={{ width: '100%' }}
                    >
                        {checkRequestProduct[0].name}
                    </StyledBadge>
                ),
                type: 'item',
                url: '/request',
                breadcrumbs: false
            });
        }

        const checkNotifyProduct = menus.filter((x) => x.name === 'Notify Product');
        if (checkNotifyProduct.length > 0) {
            products.children.push({
                id: 'notify',
                title: (
                    <StyledBadge
                        // badgeContent={cartItems && cartItems.length ? cartItems.length : '0'}
                        badgeContent={counts.notification || '0'}
                        style={{ width: '100%' }}
                    >
                        {checkNotifyProduct[0].name}
                    </StyledBadge>
                ),
                type: 'item',
                url: '/notify',
                breadcrumbs: false
            });
        }

        return products;
    } else {
        return {};
    }
};

export default product;
