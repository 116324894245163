import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GoArrowLeft } from 'react-icons/go';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            buttonTitle,
            buttonLink,
            handleAdd,
            isBack,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();
        const navigate = useNavigate();
        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.primary[200] + 75,
                    ':hover': {
                        boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && (
                    <>
                        <Grid display="flex" sx={{ padding: '24px' }} alignItems="center" justifyContent="space-between">
                            <Grid display="flex" sx={{ cursor: 'pointer' }}>
                                {isBack === true && <GoArrowLeft size={27} onClick={() => navigate(-1)} />}
                                <CardHeader sx={{ headerSX, padding: '0px', marginLeft: '5px' }} title={title} action={secondary} />
                            </Grid>
                            {buttonTitle && buttonLink && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    component={Link}
                                    to={buttonLink}
                                    sx={{ ...theme.typography.text_white_hover }}
                                >
                                    {buttonTitle}
                                </Button>
                            )}
                            {buttonTitle && handleAdd && (
                                <Button variant="contained" color="secondary" onClick={(e) => handleAdd()}>
                                    {buttonTitle}
                                </Button>
                            )}
                        </Grid>
                    </>
                )}

                {darkTitle && title && (
                    <>
                        <Grid display="flex" sx={{ padding: '24px' }} alignItems="center">
                            <Grid display="flex" justifyContent="space-evenly">
                                {isBack === true && <GoArrowLeft onClick={() => navigate(-1)} size={27} />}
                                <CardHeader sx={{ headerSX, padding: '0px' }} title={title} action={secondary} />
                            </Grid>
                            {buttonTitle && buttonLink && (
                                <Button variant="contained" color="secondary" component={Link} to={buttonLink}>
                                    {buttonTitle}
                                </Button>
                            )}
                            {buttonTitle && handleAdd && (
                                <Button variant="contained" color="secondary" onClick={(e) => handleAdd()}>
                                    {buttonTitle}
                                </Button>
                            )}
                        </Grid>
                    </>
                )}
                {/* content & header divider */}

                {title && <Divider />}
                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
