import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
// import 'toastr/toastr.scss';
import StoredVariables, { GetLocalSession, GetSession, SetLocal, SetLocalSession, SetSession } from 'constant/Session';
import { GET, POST } from 'constant/RequestAuthService';
import { DECODE, ENCODE, Json, Reload, Stringify } from 'constant/Util';
import { useLocation, useNavigate } from 'react-router';
// ==============================|| APP ||============================== //

const queryClient = new QueryClient();
const App = () => {
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        defaultImages();
    }, []);
    const defaultImages = async () => {
        const response = await GET('images');
        // console.log(response);
        if (response.status === 200) {
            await SetLocal(StoredVariables.productImage, response.data[3].credencial_content);
            await SetLocal(StoredVariables.backendlogo, response.data[2].credencial_content);
        }
    };

    const rememberme = GetLocalSession(StoredVariables.RememberMe);
    const savedEmail = GetLocalSession(StoredVariables.email);
    const savedPassword = GetLocalSession(StoredVariables.password);

    const isRememberme = rememberme && Json(DECODE(GetLocalSession(StoredVariables.RememberMe)));
    const email = savedEmail && Json(DECODE(GetLocalSession(StoredVariables.email)));

    const password = savedPassword && Json(DECODE(GetLocalSession(StoredVariables.password)));

    const isAlreadyLogin = GetSession(StoredVariables.logindata);

    const navigate = useNavigate();
    const { pathname } = useLocation();

    // console.log(pathname === '/login');

    useEffect(() => {
        const isUserExist = async () => {
            if (isRememberme && email && password && !isAlreadyLogin) {
                const Login = {
                    email,
                    password
                };

                const response = await POST('admin/login', Login);
                if (response.status === 200) {
                    SetLocalSession(StoredVariables.email, ENCODE(Stringify(Login.email)));
                    SetLocalSession(StoredVariables.password, ENCODE(Stringify(Login.password)));
                    SetLocalSession(StoredVariables.username, response.data.first_name);
                    SetLocalSession(StoredVariables.loginImage, response.data.picture);
                    SetLocalSession(StoredVariables.menus, Stringify(response.data.menus));

                    await SetSession(StoredVariables.logindata, ENCODE(Stringify(response.data)));
                    await SetSession(StoredVariables.username, response.data.first_name);
                    await SetSession(StoredVariables.loginImage, response.data.picture);
                    await SetSession(StoredVariables.menus, Stringify(response.data.menus));
                    if (pathname === '/') {
                        navigate('/dashboard');
                    } else {
                        Reload();
                    }
                }
            } else {
                return;
            }
        };
        isUserExist();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <QueryClientProvider client={queryClient}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </QueryClientProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
