import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sidebar routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));
// const FormPage = Loadable(lazy(() => import('views/FormPage')));
const AdminUser = Loadable(lazy(() => import('views/AdminUser')));
const Groups = Loadable(lazy(() => import('views/Groups')));
const User = Loadable(lazy(() => import('views/User')));
const Promotional = Loadable(lazy(() => import('views/Promotional')));
const Driver = Loadable(lazy(() => import('views/Driver')));
const PosProduct = Loadable(lazy(() => import('views/PosProduct')));
const OnlineProduct = Loadable(lazy(() => import('views/OnlineProduct')));
const MappedProduct = Loadable(lazy(() => import('views/MappedProduct')));
const POSMappedProduct = Loadable(lazy(() => import('views/POSMappedProduct')));
const AddMappedProduct = Loadable(lazy(() => import('views/MappedProduct/AddMappedproduct')));
const AddNewMappedProduct = Loadable(lazy(() => import('views/POSMappedProduct/AddPOSMappedProduct')));
const EditMappedProduct = Loadable(lazy(() => import('views/MappedProduct/EditMappedproduct')));
const EditNewMappedProduct = Loadable(lazy(() => import('views/POSMappedProduct/EditPOSMappedProduct')));
const Category = Loadable(lazy(() => import('views/Category')));
const SubCategory = Loadable(lazy(() => import('views/SubCategory')));
const City = Loadable(lazy(() => import('views/City')));
const DynamicPages = Loadable(lazy(() => import('views/DynamicPages')));
const Credentials = Loadable(lazy(() => import('views/Credentials')));

const Company = Loadable(lazy(() => import('views/Company')));
const Composition = Loadable(lazy(() => import('views/Composition')));

const Order = Loadable(lazy(() => import('views/Order')));
const Report = Loadable(lazy(() => import('views/Reports')));
const DynamicSlider = Loadable(lazy(() => import('views/DynamicSlider')));
const DynamicBanner = Loadable(lazy(() => import('views/DynamicBanner')));
const DynamicAds = Loadable(lazy(() => import('views/DynamicAds')));
const Profile = Loadable(lazy(() => import('views/Profile')));
const MaintanceMode = Loadable(lazy(() => import('views/MaintanceMode')));

const ContactUs = Loadable(lazy(() => import('views/ContactUs')));

const Subject = Loadable(lazy(() => import('views/Subject')));
const Product = Loadable(lazy(() => import('views/Product')));
const Prescription = Loadable(lazy(() => import('views/Prescription')));
const OrderDetail = Loadable(lazy(() => import('views/OrderDetails')));
const UserDetail = Loadable(lazy(() => import('views/UserView')));
const ProductRequest = Loadable(lazy(() => import('views/ProductRequest')));
const NotifyProduct = Loadable(lazy(() => import('views/NotifyProduct')));

// internal page
const AddUser = Loadable(lazy(() => import('views/User/AddUser')));
const EditUser = Loadable(lazy(() => import('views/User/EditUser')));
const AddAdminUser = Loadable(lazy(() => import('views/AdminUser/AddAdminUser')));
const EditAdminUser = Loadable(lazy(() => import('views/AdminUser/EditAdminUser')));
const AddCategory = Loadable(lazy(() => import('views/Category/AddCategory')));
const EditCategory = Loadable(lazy(() => import('views/Category/EditCategory')));
const AddSubCategory = Loadable(lazy(() => import('views/SubCategory/AddSubCategory')));
const EditSubCategory = Loadable(lazy(() => import('views/SubCategory/EditSubCategory')));
const AddDeliveryPerson = Loadable(lazy(() => import('views/Driver/AddDeliveryPerson')));
const EditDeliveryPerson = Loadable(lazy(() => import('views/Driver/EditDeliveryPerson')));
const AddCompany = Loadable(lazy(() => import('views/Company/AddCompany')));
const EditCompany = Loadable(lazy(() => import('views/Company/EditCompany')));
const AddComposition = Loadable(lazy(() => import('views/Composition/AddComposition')));
const EditComposition = Loadable(lazy(() => import('views/Composition/EditComposition')));
const EditDynamicPages = Loadable(lazy(() => import('views/DynamicPages/EditPages')));
const UpdateContactUs = Loadable(lazy(() => import('views/ContactUs/EditContactUs')));
const AddProduct = Loadable(lazy(() => import('views/Product/AddProduct')));
const EditProduct = Loadable(lazy(() => import('views/Product/EditProduct')));
const ProductDetail = Loadable(lazy(() => import('views/Product/ProductDetail')));
const EditCredential = Loadable(lazy(() => import('views/Credentials/EditCredential')));
const EditLogo = Loadable(lazy(() => import('views/Credentials/EditLogo')));
const MakeOrder = Loadable(lazy(() => import('views/Order/MakeOrder')));
const UpdateOrder = Loadable(lazy(() => import('views/Order/UpdateOrder')));
const CompanyImport = Loadable(lazy(() => import('views/import/Company')));
const CompositionImport = Loadable(lazy(() => import('views/import/Composition')));
const ProductImport = Loadable(lazy(() => import('views/import/Product')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'dashboard',
            element: <DashboardDefault />
        },
        // {
        //     path: 'dashboard',
        //     children: [
        //       }
        //           {
        //             path: 'default',
        //             element: <DashboardDefault />
        //     ]
        // },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        // {
        //     path: 'sample-page',
        //     element: <SamplePage />
        // },
        // {
        //     path: 'form',
        //     element: <FormPage />
        // },
        {
            path: 'employee',
            element: <AdminUser />
        },
        {
            path: 'groups',
            element: <Groups />
        },
        {
            path: 'user',
            element: <User />
        },
        {
            path: 'driver',
            element: <Driver />
        },
        {
            path: 'posproduct',
            element: <PosProduct />
        },
        {
            path: 'onlineproduct',
            element: <OnlineProduct />
        },
        {
            path: 'mappedproduct',
            element: <MappedProduct />
        },
        {
            path: 'posmappedproduct',
            element: <POSMappedProduct />
        },
        {
            path: 'category',
            element: <Category />
        },
        {
            path: 'subcategory',
            element: <SubCategory />
        },
        {
            path: 'credentials',
            element: <Credentials />
        },
        {
            path: 'city',
            element: <City />
        },
        {
            path: 'dynamicpages',
            element: <DynamicPages />
        },

        {
            path: 'company',
            element: <Company />
        },
        {
            path: 'composition',
            element: <Composition />
        },
        {
            path: 'orders',
            element: <Order />
        },
        {
            path: 'updateorder/:id',
            element: <UpdateOrder />
        },
        {
            path: 'report',
            element: <Report />
        },
        {
            path: 'dynamicslider',
            element: <DynamicSlider />
        },
        {
            path: 'dynamicbanner',
            element: <DynamicBanner />
        },
        {
            path: 'dynamicads',
            element: <DynamicAds />
        },
        {
            path: 'profile',
            element: <Profile />
        },

        {
            path: 'maintancemode',
            element: <MaintanceMode />
        },
        {
            path: 'adduser',
            element: <AddUser />
        },
        {
            path: 'updateuser/:id',
            element: <EditUser />
        },
        {
            path: 'addadminuser',
            element: <AddAdminUser />
        },
        {
            path: 'updateadminuser/:id',
            element: <EditAdminUser />
        },
        {
            path: 'addcategory',
            element: <AddCategory />
        },
        {
            path: 'updatecategory/:id',
            element: <EditCategory />
        },
        {
            path: 'addsubcategory',
            element: <AddSubCategory />
        },
        {
            path: 'updatesubcategory/:id',
            element: <EditSubCategory />
        },
        {
            path: 'adddeliveryperson',
            element: <AddDeliveryPerson />
        },
        {
            path: 'updatedeliveryperson/:id',
            element: <EditDeliveryPerson />
        },
        {
            path: 'addcompany',
            element: <AddCompany />
        },
        {
            path: 'updatecompany/:id',
            element: <EditCompany />
        },
        {
            path: 'addcomposition',
            element: <AddComposition />
        },
        {
            path: 'updatecomposition/:id',
            element: <EditComposition />
        },
        {
            path: 'updatepage/:id',
            element: <EditDynamicPages />
        },
        {
            path: 'contactus',
            element: <ContactUs />
        },
        {
            path: 'updatecontactus/:id',
            element: <UpdateContactUs />
        },
        {
            path: 'subject',
            element: <Subject />
        },
        {
            path: 'product',
            element: <Product />
        },
        {
            path: 'addproduct',
            element: <AddProduct />
        },
        {
            path: 'updateproduct/:id',
            element: <EditProduct />
        },
        {
            path: 'updatecredential/:id',
            element: <EditCredential />
        },
        {
            path: 'updatelogo/:id',
            element: <EditLogo />
        },
        {
            path: 'prescriptions',
            element: <Prescription />
        },
        {
            path: 'view-order/:id',
            element: <OrderDetail />
        },
        {
            path: 'view-user/:id',
            element: <UserDetail />
        },
        {
            path: 'make-order/:id',
            element: <MakeOrder />
        },
        {
            path: 'addmappedproduct',
            element: <AddMappedProduct />
        },
        {
            path: 'addposmappedproduct',
            element: <AddNewMappedProduct />
        },
        {
            path: 'updatemappedproduct/:id',
            element: <EditMappedProduct />
        },
        {
            path: 'updatenewmappedproduct/:id',
            element: <EditNewMappedProduct />
        },
        {
            path: 'productdetail/:id',
            element: <ProductDetail />
        },
        {
            path: 'company/import',
            element: <CompanyImport />
        },
        {
            path: 'composition/import',
            element: <CompositionImport />
        },
        {
            path: 'product/import',
            element: <ProductImport />
        },
        {
            path: 'request',
            element: <ProductRequest />
        },
        {
            path: 'notify',
            element: <NotifyProduct />
        },
        {
            path: 'promotional',
            element: <Promotional />
        }
    ]
};

export default MainRoutes;
