// assets
import { IconDashboard } from '@tabler/icons';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
// constant
const icons = { IconDashboard };

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        border: `2px solid var(--primary)`,
        padding: '0 4px',
        background: '#673ab7',
        color: '#fff'
    }
}));

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const order = (menus, counts) => {
    const check = menus.filter((x) => x.name === 'Prescriptions' || x.name === 'Orders');
    if (check.length > 0) {
        const order = {
            id: 'order',
            title: 'Order Management',
            type: 'group',
            children: []
        };
        const checkPrescriptions = menus.filter((x) => x.name === 'Prescriptions');

        if (checkPrescriptions.length > 0) {
            {
                order.children.push({
                    id: 'Prescription',
                    title: (
                        <StyledBadge
                            // badgeContent={cartItems && cartItems.length ? cartItems.length : '0'}
                            badgeContent={counts.prescriptions || '0'}
                            style={{ width: '100%' }}
                        >
                            {checkPrescriptions[0].name}{' '}
                        </StyledBadge>
                    ),
                    type: 'item',
                    url: '/prescriptions',
                    icon: icons.IconShadow,
                    breadcrumbs: false
                });
            }
        }
        const checkOrders = menus.filter((x) => x.name === 'Orders');
        if (checkOrders.length > 0) {
            order.children.push({
                id: 'order',
                title: (
                    <StyledBadge
                        // badgeContent={cartItems && cartItems.length ? cartItems.length : '0'}
                        badgeContent={counts.orders || '0'}
                        style={{ width: '100%' }}
                    >
                        {checkOrders[0].name}{' '}
                    </StyledBadge>
                ),
                type: 'item',
                url: '/orders',
                icon: icons.IconShadow,
                breadcrumbs: false
            });
        }
        sessionStorage.setItem('page', 1);
        sessionStorage.setItem('search', '');
        sessionStorage.setItem('order', '');
        return order;
    } else {
        return {};
    }
};

export default order;
